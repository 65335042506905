<template>
  <v-row>
  <v-col v-for="(item, idx) in siteData.customers.filter(x => (!corporate && x.showcase) || (corporate && x.corporate))" :key="idx" cols="6" :md="corporate?3:4">
    <a v-if="item.url" :href="item.url" target="_blank">
    <v-sheet :elevation="1" class="text-center pa-4" height="90" color="transparent" :title="item.name" style="background-color: rgba(255,255,255,0.1) !important; line-height:85px;">
      <img :src="`https://sodisp.imgix.net/web/customers/${item.id}-white.png?h=60`" :alt="item.name" class="d-inline-block" :style="`max-width:100%; max-height:60px;${dark?'filter:invert(70%);':''}`"/>
    </v-sheet>
    </a>
    <v-sheet v-else :elevation="1" class="text-center pa-4" height="90" color="transparent" :title="item.name" style="background-color: rgba(255,255,255,0.1) !important; line-height:85px;">
      <img :src="`https://sodisp.imgix.net/web/customers/${item.id}-white.png?h=60`" :alt="item.name" class="d-inline-block" :style="`max-width:100%; max-height:60px;${dark?'filter:invert(70%);':''}`"/>
    </v-sheet>

  </v-col>
  </v-row>
</template>

<script>
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SectionIntegrations",
  components: {
  },
  props: {
    showcase: { type: Boolean, default: true },
    corporate: { type: Boolean, default: false },
    dark: Boolean,
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>